import type { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import Image from "next/image";
import { getServerSession } from "next-auth/next";
import { getProviders, signIn } from "next-auth/react";

import LogoSrc from "@/images/octolense-logo-w-text.png";
import { authOptions } from "@/server/auth";

export default function SignIn(_props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	return (
		<>
			<div className="-mt-20 flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
				<div className="w-full max-w-sm">
					<div>
						<Image className="mx-auto h-36 w-auto" src={LogoSrc} alt="Octolense Logo" />
						<h2 className="mt-12 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
							Sign in to your account
						</h2>
						{/* Add a subtitle that says: "Coming Soon!" */}
						<p className="mt-2 text-center text-sm text-gray-600">
							We are currently in <strong>public beta</strong>.{" "}
							<a
								href="https://discord.gg/nZsKaZtJyr"
								target="_blank"
								rel="noopener noreferrer"
								className="underline hover:no-underline"
							>
								Feedback
							</a>{" "}
							is highly appreciated.
						</p>
					</div>

					<div className="mt-8 rounded-md bg-white px-10 py-8">
						<button
							onClick={() => signIn("github")}
							className="flex w-full items-center justify-center gap-3 rounded-md bg-[#24292F] px-3 py-3 text-white transition-colors duration-200 ease-in-out hover:bg-[#2F363D] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#24292F] disabled:opacity-70"
						>
							<svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
								<path
									fillRule="evenodd"
									d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
									clipRule="evenodd"
								/>
							</svg>
							<span className="text-sm font-semibold leading-6">Sign in with GitHub</span>
						</button>
					</div>
				</div>
			</div>
		</>
	);
}

export async function getServerSideProps(context: GetServerSidePropsContext) {
	const session = await getServerSession(context.req, context.res, authOptions);

	// If the user is already logged in, redirect.
	// Note: Make sure not to redirect to the same page
	// To avoid an infinite loop!
	if (session) {
		return { redirect: { destination: "/" } };
	}

	const providers = await getProviders();

	return {
		props: { providers: providers ?? [] },
	};
}
